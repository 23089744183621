import React, { useState, useRef, useEffect } from "react";
import { 
  Box, 
  Flex, 
  Spacer, 
  Button, 
  useMediaQuery, 
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import BurgerMenu from "./BurgerMenu";
import { MdExitToApp } from "react-icons/md";
import { ChevronDownIcon } from "@chakra-ui/icons";
import useI18n from "../../hooks/useI18n";
import Logo from "./Logo";

// Import flag images
import ukFlag from "../../images/flags/ua.svg";
import enFlag from "../../images/flags/gb.svg";
import plFlag from "../../images/flags/pl.svg";
import deFlag from "../../images/flags/de.svg";
import esFlag from "../../images/flags/es.svg";
import itFlag from "../../images/flags/it.svg";

const APP_URL = process.env.REACT_APP_APP_URL || 'https://app.enhance-your-ability.com';

// Define language options with their flag images
const languageOptions = [
  { value: "uk", label: "UA", flag: ukFlag },
  { value: "en", label: "EN", flag: enFlag },
  { value: "pl", label: "PL", flag: plFlag },
  { value: "de", label: "DE", flag: deFlag },
  { value: "es", label: "ES", flag: esFlag },
  { value: "it", label: "IT", flag: itFlag },
];

const Header: React.FC = () => {
  const handleLoginClick = () => {
    const loginUrl = new URL(`${APP_URL}/auth/sign-in`);
    loginUrl.searchParams.set('lang', currentLanguage);
    window.location.href = loginUrl.toString();
  };

  const [isMobileDevice] = useMediaQuery("(min-width: 768px)");
  const location = useLocation();
  const ml = isMobileDevice ? "8vw" : "5vw";
  const { t, changeLanguage, currentLanguage } = useI18n("header");

  const handleLanguageChange = (newLanguage: string) => {
    changeLanguage(newLanguage);
    localStorage.setItem('landing_language_preference', newLanguage);
  };

  // Desktop menu items component
  const MenuItems = () => (
    <>
      <Box
        _hover={{ color: "#2B381F" }}
        color={location.pathname === "/contact-us" ? "#2B381F" : undefined}
      >
        <Link to="/contact-us">{t("contactUs")}</Link>
      </Box>
      <Box
        _hover={{ color: "#2B381F" }}
        color={location.pathname === "/faq" ? "#2B381F" : undefined}
      >
        <Link to="/faq">{t("faq")}</Link>
      </Box>
      <Box
        _hover={{ color: "#2B381F" }}
        color={location.pathname === "/blog" ? "#2B381F" : undefined}
      >
        <Link to="/blog">{t("blog")}</Link>
      </Box>
      <Button
        onClick={handleLoginClick}
        rightIcon={<MdExitToApp />}
        fontWeight="bold"
        color="rgba(41, 49, 28, 1)"
        cursor="pointer"
        background="none"
        border="none"
        fontSize={20}
        _hover={{ textDecoration: "underline" }}
      >
        {t("login")}
      </Button>
    </>
  );

  // Get the currently selected language option
  const selectedLanguage = languageOptions.find(
    (option) => option.value === currentLanguage
  ) || languageOptions[0];

  // Custom language selector with flags inside dropdown
  const LanguageSelector = ({ className }: { className?: string }) => (
    <Menu>
      <MenuButton 
        as={Button} 
        rightIcon={<ChevronDownIcon mt={"-3px"}/>}
        variant="ghost"
        bg="rgb(234, 234, 243)"
        _hover={{ bg: "rgb(227, 227, 238)" }}
        className={className}
      >
        <Flex alignItems="center">
          <Image 
        src={selectedLanguage.flag} 
        alt={`${selectedLanguage.label} flag`} 
        boxSize="20px" 
        mr={2} 
          />
          <Text fontWeight="normal">{selectedLanguage.label}</Text>
        </Flex>
      </MenuButton>
      <MenuList 
        minW="100px" 
        bg="rgb(255, 255, 255)" 
        boxShadow="md" 
        borderRadius="md"
      >
        {languageOptions.map((option) => (
          <MenuItem 
            key={option.value} 
            onClick={() => handleLanguageChange(option.value)}
            bg={option.value === currentLanguage ? "rgb(234, 234, 243)" : undefined}
            _hover={{ bg: "rgb(234, 234, 243)" }}
          >
            <Flex alignItems="center">
              <Image 
                src={option.flag} 
                alt={`${option.label} flag`} 
                boxSize="20px" 
                mr={2} 
              />
              <Text fontSize="sm" color="gray.700">{option.label}</Text>
            </Flex>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );

  return (
    <Flex
      as="header"
      alignItems="center"
      fontFamily="Poppins Polly, sans-serif"
    >
      <Box ml={ml} mt="1">
        <Logo size={isMobileDevice ? "100px" : "80px"} />
      </Box>

      <Spacer />

      {isMobileDevice ? (
        <Flex align="center" gap={8} fontSize={20} color="#2B381F8c" mr="7vw">
          <LanguageSelector className="language-selector" />
          <MenuItems />
        </Flex>
      ) : (
        <Flex>
          <LanguageSelector className="mr-2vw language-selector" />
          <Box mr="7vw">
            <BurgerMenu />
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default Header;