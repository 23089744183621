import React from "react";
import { Box, Image, Flex, calc } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import LogoImage from "../../images/EYA_Nazva.png";
import LogoImage2 from "../../images/Zelenyi_EYA.png";

const Logo: React.FC<{ size?: string }> = ({ size = "120px" }) => {
  return (
    <Box>
      <Link to="/">
        <Flex align="center" justifyContent="left">
          <Image height={size} src={LogoImage2} alt="EYA Logo" />
          <Image height={`calc(${size} - 13px)`} src={LogoImage} alt="EYA Logo" />
        </Flex>
      </Link>
    </Box>
  );
};

export default Logo;
