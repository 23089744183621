import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  VStack,
  Spacer,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdExitToApp } from "react-icons/md";
import Logo from "./Logo";
import useI18n from "../../hooks/useI18n";

const BurgerMenu: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const [showOverlay, setShowOverlay] = useState(false);
  const { t, changeLanguage } = useI18n("header");
  const navigate = useNavigate();

  const handleLoginClick = () => {
    window.location.replace("https://app.enhance-your-ability.com");
    // navigate("/login");
  };

  // Ensure menu closes when navigating to another page
  useEffect(() => {
    onClose();
  }, [location.pathname, onClose]);

  useEffect(() => {
    if (isOpen) {
      setShowOverlay(true);
    } else {
      const timer = setTimeout(() => setShowOverlay(false), 300); // 300ms to match the close animation
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  return (
    <>
      <Button onClick={onOpen} border="none">
        <HamburgerIcon />
      </Button>

      {showOverlay && (
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay>
            <DrawerContent>
              <DrawerCloseButton mr="6vw" mt="4vh" />
              <DrawerBody display="flex" flexDirection="column">
                <Logo size="80px" />

                <VStack
                  spacing={8}
                  fontSize={24}
                  color="#2b143b8c"
                  fontFamily="Poppins Polly, sans-serif"
                  mt={32}
                >
                  <Box
                    _hover={{ color: "#2b143b" }}
                    color={
                      location.pathname === "/contact-us"
                        ? "#2b143b"
                        : undefined
                    }
                  >
                    <Link to="/contact-us" onClick={onClose}>{t("contactUs")}</Link>
                  </Box>
                  <Box
                    _hover={{ color: "#2b143b" }}
                    color={location.pathname === "/faq" ? "#2b143b" : undefined}
                  >
                    <Link to="/faq" onClick={onClose}>
                      {t("faq")}
                    </Link>
                  </Box>
                  <Box
                    _hover={{ color: "#2b143b" }}
                    color={
                      location.pathname === "/blog" ? "#2b143b" : undefined
                    }
                  >
                    <Link to="/blog" onClick={onClose}>
                      {t("blog")}
                    </Link>
                  </Box>

                  <Button
                    onClick={handleLoginClick}
                    rightIcon={<MdExitToApp />}
                    fontWeight="bold"
                    background="none"
                    border="none"
                    color="rgba(41, 49, 28, 1)"
                    cursor="pointer"
                    fontSize={24}
                    _hover={{ textDecoration: "underline" }}
                  >
                    {t("continueBtn")}
                    <Spacer />
                  </Button>
                </VStack>
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      )}
    </>
  );
};

export default BurgerMenu;
