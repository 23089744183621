import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "uk",
    supportedLngs: ["uk", "en", "pl", "de", "es", "it"],
    fallbackLng: "uk",
    ns: [
      "common",
      "auth",
      "main",
      "categories",
      "contactUs",
      "underDevelopmentPage",
    ],
    detection: {
      order: [
        "querystring",
        "cookie",
        "localStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      caches: ["localStorage", "cookie"],
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    react: {
      useSuspense: true,
    },
    debug: false,
  });

export default i18n;
